<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo and title -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link
            to="/"
            class="d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="30px"
              max-width="30px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            <h2 class="text-2xl font-weight-semibold">
              {{ appName }}
            </h2>
          </router-link>
        </v-card-title>

        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Reset Password 🔒
          </p>
          <p class="mb-2">
            Your new password must be different from previously used passwords
          </p>
        </v-card-text>

        <!-- login form -->
        <v-card-text>
          <v-form
            ref="resetForm"
          >
            <v-text-field
              v-model="userName"
              outlined
              label="UserName"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Current Password"
              placeholder="············"
              :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              class="mb-3"
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="newPassword"
              outlined
              :type="isNewPasswordVisible ? 'text' : 'password'"
              label="New Password"
              placeholder="············"
              :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
              hide-details
              @click:append="isNewPasswordVisible = !isNewPasswordVisible"
            ></v-text-field>

            <v-btn
              block
              color="primary"
              class="mt-4"
              @click="submitForm"
            >
              Set New Password
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- back to login -->
        <v-card-actions class="d-flex justify-center align-center">
          <router-link
            :to="{name:'auth-login'}"
            class="d-flex align-center text-sm"
          >
            <v-icon
              size="24"
              color="primary"
            >
              {{ icons.mdiChevronLeft }}
            </v-icon>
            <span>Back to login</span>
          </router-link>
        </v-card-actions>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    >

    <!-- tree -->
    <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <!-- tree  -->
    <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, onUnmounted } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import store from '@/store'
import authenticationService from '@/services/AuthenticationService'
import useAuthenticationService from '@/store/useAuthenticationService'
import { useRouter } from '@core/utils'

export default {
  setup() {
    const AUTH_APP = 'app-auth'

    // Register module
    if (!store.hasModule(AUTH_APP)) {
      store.registerModule(AUTH_APP, authenticationService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(AUTH_APP)) store.unregisterModule(AUTH_APP)
    })

    const { resetPassword, userResponse, errorMessages } = useAuthenticationService()

    const isPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const password = ref('')
    const newPassword = ref('')
    const userName = ref('')
    const resetForm = ref(null)
    const { router } = useRouter()

    const validate = authClaimDTO => {
      if (resetForm.value.validate(authClaimDTO)) {
        return true
      }

      return false
    }

    const redirect = user => {
      try {
        // console.log(user)

        // On success redirect to home
        router.push('/')
      } catch (err) {
        errorMessages.validation.push('Invalid Entry')

        // console.log(err)
      }
    }

    return {
      isPasswordVisible,
      isNewPasswordVisible,
      password,
      newPassword,
      userName,

      resetPassword,
      userResponse,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      resetForm,
      validate,
      redirect,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
  methods: {
    submitForm() {
      // console.log('submit reached')

      const userDTO = { UserName: this.userName, PasswordHash: this.newPassword, NPINumber: 0 }

      if (this.validate(userDTO)) {
        try {
          this.resetPassword(userDTO, this.password)

          this.$watch('userResponse', () => {
            this.redirect(this.userResponse)
          })
        } catch (error) {
          // console.log(error)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>
